import { useEffect, useRef, useState } from 'react';
import { Card, CircularProgress, Link, CardMedia, CardContent, Typography, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import bank4 from "../../images/bank4.jpeg";
import investorflow_logo from '../../images/investorflow_logo.png';

import './InvestorFlowNewsPOC.css';

let resizeObserver;

const InvestorFlowNewsPaging = () => {
  const [searchParams] = useSearchParams();
  const itemCount = searchParams.get("itemCount") ? parseInt(searchParams.get("itemCount")) : 5

  const [latestNews, setLatestNews] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [visibleItemCount, setVisibleItemCount] = useState(itemCount);

  //const endpoint = process.env.REACT_APP_BUILD === 'development' ? 'http://localhost:7071/api' : 'https://investorflowanywhereserverless.azurewebsites.net/api';
  const endpoint = 'https://investorflowanywhereserverless.azurewebsites.net/api';

  const serverlessEndpoint = `${endpoint}/investorflow-resources`;

  /* START RESIZE */
  const containerRef = useRef();
  if (!resizeObserver && containerRef?.current) {
    resizeObserver = new ResizeObserver((entries) => {
      console.log('entries', entries);
      const detail = {
        typeOfOperation: "window_resize",
        cmpId: window.name,
        height: entries[0].contentRect.height + "px"
      };
      console.log('detail', detail);
      window.parent.postMessage(JSON.stringify(detail), "*");
    });
    resizeObserver.observe(containerRef.current);
  };
  /* END RESIZE */

  useEffect(() => {
    const fetchData = async () => {
      console.log("new-paging");
      try {
        const response = await axios.get(serverlessEndpoint);
        console.log(response?.data?.sources);
        setLatestNews(response?.data?.sources);
      } catch (error) {
        console.log('error fetching from serverless function', error);
      };
    };

    fetchData();

    if (window && !isReady) {
      window.addEventListener("message", messageHandler);
      if (window.parent) {
        window.parent.postMessage('READY', "*");
        console.log("fired ready event from React.");
        setIsReady(true);
      }
    }
  }, []);

  const downloadAttachment = (url) => {
    if (window?.parent) {
      const detail = {
        typeOfOperation: "download_attachment",
        cmpId: window.name,
        downloadUrl: url
      };
      console.log(detail);
      window.parent.postMessage(JSON.stringify(detail), "*");
    };
  };

  const messageHandler = (event) => {
    if (event.data?.hello || event.data?.type === 'webpackWarnings' || event.data === 'READY') return;
    if (event.data?.typeOfOperation) {
      console.log('event.data', event.data);
      try {
        const parsedData = JSON.parse(event.data);
        console.log("React received event", parsedData);
      } catch (e) {
        console.log('error', e);
      };
    };
  };


  return (
    <>
      {latestNews?.length > 0 ?
        <div ref={containerRef} className="page-container">
          <div className="body-container">

            <div className="latest-news-container">
              <div className="latest-news-header">
                <div className="latest-news-header-title">
                  <img src={investorflow_logo} alt="logo" />
                  <Typography variant="h6" sx={{ paddingLeft: "8px" }}>Investor</Typography>
                  <Typography variant="h6"><b>Flow</b></Typography>
                  <Typography variant="h6" sx={{ paddingLeft: "4px" }}>News</Typography>
                </div>
              </div>

              {latestNews.map((news, index) =>
                (visibleItemCount > index) &&
                <div key={"latestnews" + index}>
                  <div className="latest-news-piece" data-is-hot-news={news.isHotNews}>
                    <Card className="latest-news-card">
                      <CardMedia className="latest-news-media"
                        component="img"
                        sx={{ width: 120 }}
                        image={bank4}
                      />
                      <Box className="latest-news-content-wrapper">
                        <CardContent className="latest-news-card-content">
                          <div className="info-container">
                            <div className="info-content">
                              <Typography variant="overline" className="info-type">{news.type}</Typography>
                              <Typography variant="overline" sx={{ padding: "0 8px" }}>|</Typography>
                              <Typography variant="overline" className="info-date">{news.date}</Typography>
                            </div>
                          </div>
                          {news.download
                            ?
                            <Typography onClick={() => downloadAttachment(news.href)} className="news-title cursor" variant="h2" color="#444444">
                              {news.title}
                            </Typography>
                            :
                            <Link href={news.href} underline="none" target="_blank" rel="noopener" sx={{ cursor: "pointer" }}>
                              <Typography className="news-title" variant="h2" color="#444444">
                                {news.title}
                              </Typography>
                            </Link>
                          }
                          {!!news.summary &&
                            <Typography className="news-summary" variant="body2" color="#747474">
                              {news.summary}
                            </Typography>
                          }
                        </CardContent>
                      </Box>
                    </Card>
                  </div>
                  {index < (visibleItemCount - 1) &&
                    <div className="divider" data-is-hot-news={news.isHotNews}>
                      <div style={{ width: "100%" }}></div>
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
          {visibleItemCount < latestNews.length &&
            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: "0 24px 16px" }}>
              <Link sx={{ fontSize: "16px", color: "#296187" }} href="#/" underline="hover"
                onClick={() => setVisibleItemCount(visibleItemCount + itemCount)}>
                show more...
              </Link>
            </Box>
          }
        </div>
        :
        <Box sx={{ display: 'flex', height: "100%", width: "100%", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      }
    </>
  );
};

export default InvestorFlowNewsPaging;
