import { useState } from 'react';
import { Button, Paper, Stack } from '@mui/material';
import { queryApi } from 'sec-api';
import '../App.css';


queryApi.setApiKey('ecc232cd7244dd7680f7921dacd5cf33fe7704282d75c1f1764705f4bcadeda3');

const MessagingPOC = () => {
  const [payload, setPayload] = useState(false);
  const [secFiling, setSecFiling] = useState(false);

  const messageHandler = (event) => {
    if (event.data?.hello || event.data?.type === 'webpackWarnings') return;
    console.log(event.data);
    try {
      const parsedData = JSON.parse(event.data);
      if (parsedData.recordId) {
        setPayload(parsedData);
        getSEC10kForm();
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getSEC10kForm = async () => {
    const query = {
      query: "ticker:AAPL AND formType:\"10-Q\"",
      from: "0",
      size: "10",
      sort: [{ filedAt: { order: "desc" } }]
    };

    const filings = await queryApi.getFilings(query);
    console.log(filings);
    setSecFiling(filings);
  };

  if (window) {
    if (window.parent) {
      window.parent.postMessage('READY', "*");
      console.log("fired ready event from React.");
    }
    window.addEventListener("message", messageHandler);
  };


  return (
    <div className="sample-body">
      {!payload &&
        <div>
          <Button variant="contained"
            onClick={() => {
              if (window?.parent) {
                const eventData = {
                  typeOfOperation: "configuration",
                  cmpId: window.name,
                  configuration: [
                    { key: "recordId", label: "Record Id", isText: true },
                    { key: "recordName", label: "Record Name", isText: true },
                    { key: "opportunities", label: "Opportunity Names", isQuery: true }
                  ]
                };
                window.parent.postMessage(JSON.stringify(eventData), "*");
                console.log("fired event from REACT");
              }
            }}>CONFIGURE ME</Button>
        </div>
      }
      {payload &&
        <Stack spacing={1} style={{ padding: "0.75rem", width: "100%" }}>
          <Paper style={{ backgroundColor: "#1565c0", color: "white" }}>
            Record Id
          </Paper>
          <Paper style={{ backgroundColor: "#e1f5fe" }}>
            {payload.recordId}
          </Paper>
          <Paper style={{ backgroundColor: "#1565c0", color: "white" }}>
            Record Name
          </Paper>
          <Paper style={{ backgroundColor: "#e1f5fe" }}>
            {payload.recordName}
          </Paper>
          <Paper>
            <Stack spacing={1}>
              <Paper style={{ backgroundColor: "#1565c0", color: "white" }}>Opportunities</Paper>
              {payload.opportunities.map((value, index) =>
                <Paper key={'opty_' + index} style={{ backgroundColor: "#e1f5fe" }}>
                  {value}
                </Paper>
              )}
            </Stack>
          </Paper>
        </Stack>
      }
      {secFiling &&
        <div>
        </div>
      }
    </div>
  );
};

export default MessagingPOC;
