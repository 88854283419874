import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import ChartsPOC from './components/ChartsPOC';
import DimensionPOC from './components/DimensionPOC';
import GoogleContact from './components/GoogleLink/GoogleLink';
import IframeWrapper from "./components/IframeWrapper/IframeWrapper";
import InvestorFlowLogo from "./components/InvestorFlowLogo/InvestorFlowLogo";
import InvestorFlowNews from './components/News/InvestorFlowNews';
import InvestorFlowNewsMobile from './components/News/InvestorFlowNewsMobile';
import InvestorFlowNewsPaging from './components/News/InvestorFlowNewsPaging';
import InvestorFlowNewsPOC from './components/News/InvestorFlowNewsPOC';
import InvestorFlowNewsPOCSidebar from './components/News/InvestorFlowNewsPOCSidebar';
import LinkPOC from './components/GoogleLink/LinkPOC';
import MessagingPOC from './components/MessagingPOC';
import PowerBiDemoApp from './components/PowerBi/PowerBiDemo';

import './App.css';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LinkPOC />} />
        <Route path="message" element={<MessagingPOC />} />
        <Route path="resizing" element={<DimensionPOC />} />
        <Route path="charts" element={<ChartsPOC />} />
        <Route path="news" element={<InvestorFlowNews />} />
        <Route path="news-mobile" element={<InvestorFlowNewsMobile />} />
        <Route path="news-poc" element={<InvestorFlowNewsPOC />} />
        <Route path="news-poc-mini" element={<InvestorFlowNewsPOCSidebar />} />
        <Route path="news-paging" element={<InvestorFlowNewsPaging />} />
        <Route path="power-bi" element={<PowerBiDemoApp />} />
        <Route path="google-user" element={<GoogleContact />} />
        <Route path="investorflow-css-logo" element={<InvestorFlowLogo />} />
        {/* remove the width element if you want to change params to default */}
        <Route path="iframe-wrapper" element={<IframeWrapper width="450px" />} />
        <Route path="*" element={<h1>404 check the route - this one doesnt exist</h1>} />
      </Routes>
    </Router>
  );
};

export default App;
