import { useEffect, useState } from 'react';
import { Divider, List, Box, Link, Paper, Typography, ListItem } from '@mui/material';


const GoogleLink = () => {
  const [isReady, setIsReady] = useState(false);
  const [userLink, setUserLink] = useState();
  const [userName, setUserName] = useState();


  useEffect(() => {
    if (window && !isReady) {
      window.addEventListener("message", messageHandler);
      if (window.parent) {
        window.parent.postMessage('READY', "*");
        console.log("fired ready event from React.");
        setIsReady(true);
      }
    }
  }, []);

  const messageHandler = (event) => {
    if (event.data?.hello || event.data?.type === 'webpackWarnings' || event.data === 'READY') return;
    console.log(event.data);
    try {
      const parsedData = JSON.parse(event.data);
      console.log("drytuna", parsedData);
      if (parsedData.typeOfOperation === "initialize-record-data") {
        const record = parsedData.record;
        const firstName = record?.FirstName ? record?.FirstName?.toLowerCase() : '';
        const lastName = record?.LastName ? record?.LastName?.toLowerCase() : '';
        const userId = record?.CT_PE__Hobbies__c ? record?.CT_PE__Hobbies__c : '';
        //const actionLink = `https://www.google.com/search?q=${firstName}+${lastName}`;
        const actionLink = `https://stackoverflow.com/users/${userId}/${firstName}-${lastName}`;
        console.log("action link", actionLink);

        const a = document.createElement('a')
        a.href = actionLink;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        //setUserName(`${firstName} ${lastName}`);
        //setUserLink(contactLink);
      }

    } catch (e) {
      console.log('error', e);
    };
  };

  return (
    <Box className="sample-body-link" sx={{ display: "none" }}>
      <Paper sx={{ width: "100%", border: "solid 1px rgba(0, 0, 0, 0.25)", padding: "1rem" }}>
        <Typography variant='h5' ><b>Create a Hyperlink with contact as param</b></Typography>
        <Divider component="div" sx={{ paddingTop: "0.5rem" }} />
        <List sx={{ padding: "1rem" }}>
          <ListItem>
            <Link href={`https://www.google.com/search?q=${userLink}`}
              target="_blank" underline="hover">A google Search Link to {userName}</Link>
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
};

export default GoogleLink;
