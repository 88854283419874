import { useEffect, useState } from 'react';
import { Card, CardHeader, CardMedia, CardContent, CardActions, Typography, Button, Box } from '@mui/material';
import axios from 'axios';
import bank1 from "../../images/bank1.jpg";
import bank2 from "../../images/bank2.jpeg";
import bank3 from "../../images/bank3.jpeg";
import bank4 from "../../images/bank4.jpeg";
import investorflow_logo from '../../images/investorflow_logo.png';


const InvestorFlowNews = () => {
  const [bgColor, setBgColor] = useState("#f0eed1");
  const [newsList, setNewsList] = useState([]);

  const endpoint = process.env.REACT_APP_BUILD === 'development' ? 'http://localhost:7071/api' : 'https://investorflowanywhereserverless.azurewebsites.net/api';
  // const endpoint = 'https://investorflowanywhereserverless.azurewebsites.net/api';

  const serverlessEndpoint = `${endpoint}/investorflow-resources`;

  console.log('process.env node env', process.env.NODE_ENV)
  console.log('process.env build', process.env.REACT_APP_BUILD)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(serverlessEndpoint);
        console.log(response.data);
        setNewsList(response?.data?.newsList);
        setBgColor(response?.data?.color);
      } catch (error) {
        console.log('error fetching from serverless function', error);
      };
    };

    fetchData();
  }, []);

  return (
    <div style={{ textAlign: "left", display: "flex", flexWrap: "wrap", width: "100%", minHeight: "99vh", backgroundColor: bgColor ? bgColor : '#fff', padding: "0" }}>
      <div style={{ width: "100%", padding: "24px 0", display: "flex", justifyContent: "center", backgroundColor: "#040201" }}>
        <div style={{ margin: "0 auto", display: "flex" }}>
          <img style={{ width: "70px", height: "70px" }} src={investorflow_logo} alt="logo" />
          <Typography variant="h2" sx={{ paddingLeft: "16px", color: "white" }}>Investor</Typography>
          <Typography variant="h2" sx={{ color: "white", fontWeight: "bold" }}>Flow</Typography>
          <Typography variant="h2" sx={{ paddingLeft: "16px", color: "#994133" }}>News</Typography>
        </div>
      </div>

      <div style={{ display: "grid", gridTemplateColumns: "60% 40%" }} >
        <div style={{ borderRight: "solid 2px #dbc594", paddingLeft: "32px" }}>
          <div style={{ padding: "16px 16px 16px 0" }}>
            <Card sx={{ backgroundColor: bgColor, boxShadow: "none" }}>
              <CardHeader sx={{ padding: "8px 0" }}
                titleTypographyProps={{ color: "#2a547e", fontWeight: "bold" }}
                subheaderTypographyProps={{ color: "#994133" }}
                title="InvestorFlow Announces Acquisition of Coyote Software to Create the Leading Industry Cloud for the Commercial Real Estate Industry"
                subheader="April 10, 2024"
              />
              <CardMedia
                sx={{ height: 200 }}
                image={bank2}
                title="bank1"
              />
              <CardContent sx={{ padding: "8px 0 0 0" }}>
                <Typography variant="body2" color="#040201">
                  InvestorFlow, the industry leading cloud-based platform for alternative investment firms, today announced the acquisition of Coyote Software, a UK-based software-as-a-service leader powering many of the largest Commercial Real Estate (CRE) firms in the UK and Europe. The acquisition marks an important step for the industry: InvestorFlow will now expand collaboration across all fundraising, transaction, and asset management stakeholders and provide more granular reporting to investors within the CRE ecosystem on a single platform...
                </Typography>
              </CardContent>
              <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button size="small">Share</Button>
                <Button size="small">View Full Article</Button>
              </CardActions>
            </Card>
          </div>
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <div style={{ width: "90%", border: "solid 1px #dbc594" }}></div>
          </div>
          <div style={{ padding: "16px 16px 16px 0" }}>
            <Card sx={{ backgroundColor: bgColor, boxShadow: "none" }}>
              <CardHeader sx={{ padding: "8px 0" }}
                titleTypographyProps={{ color: "#2a547e", fontWeight: "bold" }}
                subheaderTypographyProps={{ color: "#994133" }}
                title="Introducing the Spring ‘24 Release: Enhancing Deal Flow and Fundraising Efficiency"
                subheader="April 03, 2024"
              />
              <CardMedia
                sx={{ height: 140 }}
                image={bank3}
                title="bank3"
              />
              <CardContent sx={{ padding: "8px 0 0 0" }}>
                <Typography variant="body2" color="#040201">
                  InvestorFlow is designed to empower dealmakers and fundraisers with the tools they need to thrive in a fast-paced market. The Spring ‘24 release offers multiple updates, including AI functionality for interactions and relationships. These features are generally available on April 3. Here are the release highlights...
                </Typography>
              </CardContent>
              <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button size="small">Share</Button>
                <Button size="small">View Full Article</Button>
              </CardActions>
            </Card>
          </div>
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <div style={{ width: "70%", border: "solid 1px #dbc594" }}></div>
          </div>
          <div style={{ padding: "16px 16px 16px 0" }}>
            <Card sx={{ backgroundColor: bgColor, boxShadow: "none" }}>
              <CardHeader sx={{ padding: "8px 0" }}
                titleTypographyProps={{ color: "#2a547e", fontWeight: "bold" }}
                subheaderTypographyProps={{ color: "#994133" }}
                title="Practical AI for Private Markets"
                subheader="March 08, 2024"
              />
              <CardMedia
                sx={{ height: 120 }}
                image={bank1}
                title="bank1"
              />
              <CardContent sx={{ padding: "8px 0 0 0" }}>
                <Typography variant="body2" color="#040201">
                  Welcome to the inaugural post of our series on the practical application of AI in the Private Markets. Our goal is to illuminate how AI can bring about significant, practical improvements to daily workflows for deal sourcing, fundraising, and investor relations professionals. As we transition from the theoretical AI hype of 2023 into the pragmatic, result-driven ethos of 2024, we are focusing squarely on delivering tangible benefits...
                </Typography>
              </CardContent>
              <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button size="small">Share</Button>
                <Button size="small">View Full Article</Button>
              </CardActions>
            </Card>
          </div>
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <div style={{ width: "50%", border: "solid 1px #dbc594" }}></div>
          </div>
        </div>

        <div style={{}}>
          {(newsList && newsList.length > 0) && newsList.map((news, index) =>
            <div key={`news-${index}`}>
              <div style={{ padding: "16px" }}>
                <Card sx={{ backgroundColor: bgColor, boxShadow: "0", borderRadius: "0", display: 'flex' }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 120 }}
                    image={bank4}
                    alt="Live from space album cover"
                  />
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto', padding: "0 12px !important" }}>
                      <Typography component="div" variant="h6" color="#363433" sx={{ fontSize: "16px", fontWeight: "bold" }}>
                        {news.title}
                      </Typography>
                      <Typography variant="subtitle1" color="#2a547e" component="div" sx={{ fontSize: "14px" }}>
                        {news.subTitle}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </div>
              {index < (newsList.length - 1) &&
                <div style={{ width: "100%" }}>
                  <div style={{ width: (90 - Math.floor(Math.random() * 5) * 10) + "%", border: "solid 1px #dbc594" }}></div>
                </div>
              }
            </div>
          )}
        </div>
      </div>
      <div style={{ backgroundColor: "#040201", color: "white", padding: "16px 32px", width: "100%", fontSize: "12px" }}>
        <p>
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
        </p>
        <p>
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        </p>
        <p>
          "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."
        </p>
      </div>
    </div>
  );
};

export default InvestorFlowNews;
