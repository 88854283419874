// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Iframe Wrapper Styles */
root {
  --if-height: 100px;
}

.if-component-wrapper {
  border: 3px solid black;
  border-radius: 30px;
  margin: auto;
  /* margin-left: 25px;
  margin-right: 25px; */
  min-height: var(--iframe-height);
}
`, "",{"version":3,"sources":["webpack://./src/components/IframeWrapper/IframeWrapper.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ;uBACqB;EACrB,gCAAgC;AAClC","sourcesContent":["/* Iframe Wrapper Styles */\nroot {\n  --if-height: 100px;\n}\n\n.if-component-wrapper {\n  border: 3px solid black;\n  border-radius: 30px;\n  margin: auto;\n  /* margin-left: 25px;\n  margin-right: 25px; */\n  min-height: var(--iframe-height);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
