import { useEffect, useState } from 'react';
import { Box, Card, CardMedia, CardContent, CircularProgress, Link, Typography } from '@mui/material';
import axios from 'axios';
import bank1 from "../../images/bank1.jpg";
import bank2 from "../../images/bank2.jpeg";
import bank3 from "../../images/bank3.jpeg";
import bank4 from "../../images/bank4.jpeg";
import investorflow_logo from '../../images/investorflow_logo.png';

import './InvestorFlowNewsPOC.css';


const InvestorFlowNewsPOC = () => {
  const [latestNews, setLatestNews] = useState([]);
  const [hotNews, setHotNews] = useState([]);

  const endpoint = process.env.REACT_APP_BUILD === 'development' ? 'http://localhost:7071/api' : 'https://investorflowanywhereserverless.azurewebsites.net/api';
  const serverlessEndpoint = `${endpoint}/investorflow-resources`;

  //console.log('process.env node env', process.env.NODE_ENV);
  //console.log('process.env build', process.env.REACT_APP_BUILD);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(serverlessEndpoint);
        console.log(response?.data?.sources);
        setLatestNews(response?.data?.sources);
        const tempHots = [];
        const imageBank = [bank1, bank2, bank3, bank4];
        let imageCount = 0;
        response?.data?.sources?.forEach((news) => {
          if (news.isHotNews) {
            tempHots.push({ ...news, image: imageBank[imageCount] });
            imageCount = (imageCount + 1) % imageBank.length;
          }
        });
        setHotNews(tempHots);
        //setBgColor(response?.data?.color);
      } catch (error) {
        console.log('error fetching from serverless function');
      };
    };

    fetchData();
  }, []);

  const downloadAttachment = (listName, newsIndex) => {
    if (window?.parent) {
      const detail = {
        typeOfOperation: "download_attachment",
        cmpId: window.name,
        downloadUrl: listName === "hot" ? hotNews[newsIndex].url : latestNews[newsIndex].url
      };
      console.log(detail);
      window.parent.postMessage(JSON.stringify(detail), "*");
    };
  };


  return (
    <>
      {latestNews?.length > 0 ?
        <div className="page-container">
          <div className="body-container" >
            <div className="hot-news-container">
              {(hotNews?.length > 0) && hotNews.map((news, index) =>
                <div style={{ display: "flex", flexWrap: "wrap" }} key={"hotnews" + index}>
                  <div className="hot-news-piece">
                    <Card className="hot-news-card">
                      <CardMedia
                        sx={{ height: "400px", marginBottom: "12px" }}
                        image={news.image}
                        title={"bankImage" + index}
                      />
                      <CardContent className="card-content">
                        <div className="info-container">
                          <div className="info-content">
                            <Typography variant="overline" className="info-type">{news.type}</Typography>
                            <Typography variant="overline" sx={{ padding: "0 8px" }}>|</Typography>
                            <Typography variant="overline" className="info-date">{news.date}</Typography>
                          </div>
                          {/* <div className="info-actions">
                            {news.download &&
                              <img src={outline} alt="logo" onClick={() => downloadAttachment("hot", index)} />
                            }
                          </div> */}
                        </div>
                        <Link href={news.href} underline="none" target="_blank" rel="noopener" sx={{ cursor: "pointer" }}>
                          <Typography className="hot-news-title" variant="h2" color="#444444">
                            {news.title}
                          </Typography>
                        </Link>

                        {!!news.summary &&
                          <Typography className="hot-news-summary" variant="body2" color="#747474">
                            {news.summary}
                          </Typography>
                        }
                      </CardContent>
                    </Card>
                  </div>
                  <div className="divider">
                    <div style={{ width: "100%" }}></div>
                  </div>
                </div>
              )}
            </div>

            <div className="latest-news-container">
              <div className="latest-news-header">
                <div className="latest-news-header-title">
                  <img src={investorflow_logo} alt="logo" />
                  <Typography variant="h6" sx={{ paddingLeft: "8px" }}>Investor</Typography>
                  <Typography variant="h6"><b>Flow</b></Typography>
                  <Typography variant="h6" sx={{ paddingLeft: "4px" }}>News</Typography>
                </div>
              </div>

              {latestNews.map((news, index) =>
                <div key={"latestnews" + index}>
                  <div className="latest-news-piece" data-is-hot-news={news.isHotNews}>
                    <Card className="latest-news-card">
                      <CardMedia className="latest-news-media"
                        component="img"
                        sx={{ width: 120 }}
                        image={bank4}
                      />
                      <Box className="latest-news-content-wrapper">
                        <CardContent className="latest-news-card-content">
                          <div className="info-container">
                            <div className="info-content">
                              <Typography variant="overline" className="info-type">{news.type}</Typography>
                              <Typography variant="overline" sx={{ padding: "0 8px" }}>|</Typography>
                              <Typography variant="overline" className="info-date">{news.date}</Typography>
                            </div>
                            {/* <div className="info-actions">
                              {news.download &&
                                <img src={outline} alt="logo" onClick={() => downloadAttachment("latest", index)} />
                              }
                            </div> */}
                          </div>
                          <Link href={news.href} underline="none" target="_blank" rel="noopener" sx={{ cursor: "pointer" }}>
                            <Typography className="news-title" variant="h2" color="#444444">
                              {news.title}
                            </Typography>
                          </Link>
                          {!!news.summary &&
                            <Typography className="news-summary" variant="body2" color="#747474">
                              {news.summary}
                            </Typography>
                          }
                        </CardContent>
                      </Box>
                    </Card>
                  </div>
                  {index < (latestNews.length - 1) &&
                    <div className="divider" data-is-hot-news={news.isHotNews}>
                      <div style={{ width: "100%" }}></div>
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
        :
        <Box sx={{ display: 'flex', height: "100%", width: "100%", justifyContent: "center" }}>
          <CircularProgress />
        </Box>

      }
    </>
  );
};

export default InvestorFlowNewsPOC;
