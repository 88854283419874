import { useEffect, useState } from 'react';
import './IframeWrapper.css';


const IframeWrapper = ({ height="auto", source="https://1news-dev.apps.investorflow.com?items=3", width='100%' }) => {
  const [ifHeight, setIfHeight] = useState('100px');

  // should trusted domains be param passed to component?
  const trustedDomains = [
    'https://localhost:3014',
    'https://news.apps.investorflow.com',
    'https://news-dev.apps.investorflow.com'
  ];

  function badIframe(iframe) {
    return new Promise(resolve => {
      fetch(iframe.src)
        .then(res => {
          if (res.ok) return resolve(false);  // res.ok is true if the repose has an "okay status" (200-299);
          return resolve(true);
        })
        .catch(()=> resolve(true));
    });
  };

  new MutationObserver(async records => {
    for (let record of records) {
      for (let node of record.addedNodes) {
        if (node.tagName === `IFRAME` && await badIframe(node)) {
          console.log('found iframe failing to load source ---- lets handle the error', node.src)
          // invoke your handler - mybe change node.src to something else or whatever makes sense
          node.src = "https://news-dev.apps.investorflow.com?items=3"
        };
      };
    };
  }).observe(document.body, {childList: true, subtree: true});

  useEffect(() => {
    // first param is unique id - may not need if using css variable
    // second param is the origin domain we trust - will this always be news.apps.investorflow.com   ??
    if (height === "auto") {
      resizeCrossDomainIframe('make-this-unique', trustedDomains);
    };
  }, []);

  const resizeCrossDomainIframe = (id, domains) => {
    window.addEventListener('message', function(event) {
      if (!trustedDomains.includes(event.origin)) return;
      const message = JSON.parse(event.data);
      if (message?.typeOfOperation !== "window_resize") return; // only accept something which can be parsed as a number
      // console.log('message.height', message.height)
      if (message.height !== '0px') setIfHeight(message.height);
    }, false);
  };

  return (
    <iframe
      allow={"cross-origin-isolated"}
      className={`if-component-wrapper`}
      data-url={window.location.host}
      height={height}
      id={"make-this-unique"}
      name={"make-this-unique"}
      scrolling={'auto'}
      src={source}
      style={{"--iframe-height": ifHeight}}
      title={'iframe-wrapper'}
      width={width}
    />
  );
};

export default IframeWrapper;
