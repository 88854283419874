import { useState, useEffect } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import InvesorFlowIcon from "../../images/investorflow_logo.png";
import PowerBIIcon from "../../images/PowerBI_Icon.png";
import './DemoApp.css';

const sampleReportUrl = 'https://aka.ms/CaptureViewsReportEmbedConfig';


const PowerBiDemoApp = () => {

	// PowerBI Report object (to be received via callback)
	const [report, setReport] = useState();
	// Track Report embedding status
	const [isEmbedded, setIsEmbedded] = useState(false);
  // Overall status message of embedding
	const [displayMessage, setMessage] = useState(`The report is bootstrapped. Click the Embed Report button to set the access token`);
  // is filter pane hidden?
  const [isFiltersHidden, setIsFiltersHidden] = useState(false);

	// CSS Class to be passed to the embedded component
	const reportClass = 'report-container';

	// Pass the basic embed configurations to the embedded component to bootstrap the report on first load
    // Values for properties like embedUrl, accessToken and settings will be set on click of button
	const [sampleReportConfig, setReportConfig] = useState({
		type: 'report',
		embedUrl: undefined,
		tokenType: models.TokenType.Embed,
		accessToken: undefined,
		settings: undefined,
	});

	/**
	 * Map of event handlers to be applied to the embedded report
	 * Update event handlers for the report by redefining the map using the setEventHandlersMap function
	 * Set event handler to null if event needs to be removed
	 * More events can be provided from here
	 * https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/handle-events#report-events
	*/
	const [eventHandlersMap, setEventHandlersMap] = useState(new Map([
		['loaded', () => console.log('Report has loaded')],
		['rendered', () => console.log('Report has rendered')],
		['error', (event) => {
				if (event) {
					console.error(event.detail);
				}
			},
		],
		['visualClicked', () => console.log('visual clicked')],
		['pageChanged', (event) => console.log(event)],
	]));

	useEffect(() => {
		if (report) {
			report.setComponentTitle('Embedded Report');
		} else {
      embedReport();
    }
	}, [report]);

  useEffect(() => {
    const queryString = window.location.search;
    console.log('these are the current serch params', queryString);
    return () => { }
  }, []);


  /**
   * Embeds report
   *
   * @returns Promise<void>
  */
	const embedReport = async () => {
		console.log('Embed Report clicked');

		// Get the embed config from the service
		const reportConfigResponse = await fetch(sampleReportUrl);

		if (reportConfigResponse === null) {
			return;
		};

		if (!reportConfigResponse?.ok) {
			console.error(`Failed to fetch config for report. Status: ${ reportConfigResponse.status } ${ reportConfigResponse.statusText }`);
			return;
		};

		const reportConfig = await reportConfigResponse.json();

		// Update the reportConfig to embed the PowerBI report
		setReportConfig({
			...sampleReportConfig,
			embedUrl: reportConfig.EmbedUrl,
			accessToken: reportConfig.EmbedToken.Token
		});
		setIsEmbedded(true);

		// Update the display message
		setMessage('Use the buttons above to interact with the report using Power BI Client APIs.');
	};

  /**
   * Hide Filter Pane
   * @returns Promise<IHttpPostMessageResponse<void> | undefined>
  */
	const hideFilterPane = async () => {
		// Check if report is available or not
		if (!report) {
			setDisplayMessageAndConsole('Report not available');
			return;
		}

		// New settings to hide filter pane
		const settings = {
			panes: {
				filters: {
					expanded: false,
					visible: false,
				},
			},
		};

		try {
			const response = await report.updateSettings(settings);
			// Update display message
			setDisplayMessageAndConsole('Filter pane is hidden.');
      setIsFiltersHidden(true);
			return response;
		} catch (error) {
			console.error(error);
			return;
		};
	};

  /**
   * Show Filter Pane
   * @returns Promise<IHttpPostMessageResponse<void> | undefined>
  */
	const showFilterPane = async () => {
		// Check if report is available or not
		if (!report) {
			setDisplayMessageAndConsole('Report not available');
			return;
		};

		// New settings to hide filter pane
		const settings = {
			panes: {
				filters: {
					expanded: true,
					visible: true,
				},
			},
		};

		try {
			const response = await report.updateSettings(settings);
			// Update display message
			setDisplayMessageAndConsole('Filter pane is not hidden.');
      setIsFiltersHidden(false);
			return response;
		} catch (error) {
			console.error(error);
			return;
		};
	};


  /**
   * Set data selected event
   *
   * @returns void
  */
	const setDataSelectedEvent = () => {
		setEventHandlersMap(new Map([
			...eventHandlersMap,
			['dataSelected', (event) => console.log(event)],
		]));

		setMessage('Data Selected event set successfully. Select data to see event in console.');
	};

  /**
   * Change visual type
   *
   * @returns Promise<void>
  */
	const changeVisualType = async () => {
		// Check if report is available or not
		if (!report) {
			setDisplayMessageAndConsole('Report not available');
			return;
		};

		// Get active page of the report
		const activePage = await report.getActivePage();

		if (!activePage) {
			setMessage('No Active page found');
			return;
		};

		try {
			// Change the visual type using powerbi-report-authoring
			// For more information: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/report-authoring-overview
      console.log('active page = ', activePage)
			const visual = await activePage.getVisualByName('VisualContainer6');

			const response = await visual.changeType('lineChart');

			setDisplayMessageAndConsole(`The ${visual.type} was updated to lineChart.`);
			return response;
		} catch (error) {
			if (error === 'PowerBIEntityNotFound') {
				console.log('No Visual found with that name');
			} else {
				console.log(error);
			};
		};
	};

	/**
   * Set display message and log it in the console
   *
   * @returns void
  */
	const setDisplayMessageAndConsole = (message) => {
		setMessage(message);
		console.log(message);
	};

	const controlButtons =
		isEmbedded ?
		<>
			<button onClick = { changeVisualType }>Change visual type</button>
			<button onClick = { isFiltersHidden ? showFilterPane : hideFilterPane }>{`${isFiltersHidden ? "Show" : "Hide"} filter pane`}</button>
			<button onClick = { setDataSelectedEvent }>Set event</button>
			{/* <label className = "display-message">{ displayMessage }</label> */}
		</>
		:
		<>
			<label className = "display-message position">{ displayMessage }</label>
			<button onClick = { embedReport } className = "embed-report">Embed Report</button>
		</>;

	const header = <div className={"header"}>Clarity Partners Power BI Report</div>;

	const reportComponent =
		<PowerBIEmbed
			embedConfig = { sampleReportConfig }
			eventHandlers = { eventHandlersMap }
			cssClassName = { reportClass }
			getEmbeddedComponent = { (embedObject) => {
				console.log(`Embedded object of type "${ embedObject.embedtype }" received`);
				setReport(embedObject);
			} }
		/>;

	const footer =
		<div className={"footer"}>
			<p>Powered By InvestorFlow</p>
      <img title={"InvestorFlow"} alt={"InvesorFlow Icon"} className={"footer-icon"} src={InvesorFlowIcon} />
			<label className = "separator-pipe">| & </label>
      <p>PowerBI</p>
      <img title={"Power-BI"} alt={"PowerBI Icon"} className={"footer-icon"} src={PowerBIIcon} />
		</div>;

	return (
		<div className = "container">

			{ header }

			<div className = "controls">
				{ controlButtons }
				{ isEmbedded ? reportComponent : null }
			</div>

			{ footer }

		</div>
	);
};

export default PowerBiDemoApp;
