import React, { useRef, useState } from 'react';
import { Button, Paper, Stack } from '@mui/material';


let resizeObserver;
const DimensionPOC = () => {
  const [itemList, setItemList] = useState([]);
  const containerRef = useRef();

  if (!resizeObserver && containerRef?.current) {
    resizeObserver = new ResizeObserver((entries) => {
      console.log(entries);
      const detail = {
        typeOfOperation: "window_resize",
        cmpId: window.name,
        height: entries[0].contentRect.height + "px"
      };
      console.log(detail);
      window.parent.postMessage(JSON.stringify(detail), "*");
    });
    resizeObserver.observe(containerRef.current);
  };

  const showMore = () => {
    const newItems = [...itemList];
    for (let i = 0; i < 10; i++) {
      newItems.push("Sample Item");
    }
    setItemList(newItems);
  };


  return (
    <div className="sample-body" ref={containerRef}>
      <Stack spacing={1} sx={{ padding: "1rem" }}>
        <Paper>
          <Button variant="contained"
            onClick={showMore}>Add More</Button>
        </Paper>
        {itemList.map((item, index) =>
          <Paper key={'sampleItem_' + index}>
            {item + ' ' + index}
          </Paper>
        )}
      </Stack>
    </div>
  )
}

export default DimensionPOC
