import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Card, CardMedia, CardContent, Typography, Box, Link } from '@mui/material';
import bank1 from "../../images/bank1.jpg";
import investorflow_logo from '../../images/investorflow_logo.png';


let resizeObserver;

const InvestorFlowNewsMobile = () => {
  const [currentList, setCurrentList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [totalItem, setTotalItem] = useState(5);

  const endpoint = process.env.NODE_ENV === 'development' ? 'http://localhost:7071/api' : 'https://investorflowanywhereserverless.azurewebsites.net/api'
  // const endpoint = 'https://investorflowanywhereserverless.azurewebsites.net/api';

  const serverlessEndpoint = `${endpoint}/investorflow-resources`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(serverlessEndpoint);
        setNewsList(response?.data?.sources);
        setCurrentList(response?.data?.sources.slice(0, 5));
      } catch (error) {
        console.log('error fetching from serverless function');
      };
    };

    fetchData();
  }, []);


  const containerRef = useRef();
  if (!resizeObserver && containerRef?.current) {
    resizeObserver = new ResizeObserver((entries) => {
      console.log(entries);
      const detail = {
        typeOfOperation: "window_resize",
        cmpId: window.name,
        height: entries[0].contentRect.height + "px"
      };
      console.log(detail);
      window.parent.postMessage(JSON.stringify(detail), "*");
    });
    resizeObserver.observe(containerRef.current);
  };

  useEffect(() => {
    if (newsList) {
      if (totalItem < newsList.length) {
        setCurrentList(newsList.slice(0, totalItem));
      } else if (currentList.length < newsList.length) {
        setCurrentList([...newsList]);
      };
    };
  }, [totalItem, newsList]);

  const loadMoreNews = () => {
    setTotalItem(totalItem + 5);
  };

  return (
    <div ref={containerRef} style={{}}>
      <div style={{ textAlign: "left", display: "flex", flexWrap: "wrap", backgroundColor: "#f0ecc9", padding: "12px" }}>
        <img style={{ width: "20px", height: "20px" }} src={investorflow_logo} alt="logo" />
        <Typography sx={{ paddingLeft: "4px", color: "#503d2e" }}>Investor</Typography>
        <Typography sx={{ color: "#503d2e", fontWeight: "bold" }}>Flow</Typography>
        <Typography sx={{ paddingLeft: "4px", color: "#e3a72f" }}>News</Typography>
        {(currentList && currentList.length > 0) && currentList.map((news, index) =>
          <Box key={"new_" + index} sx={{ padding: "12px 0", borderBottom: (index < currentList.length - 1 ? "solid 1px #d54b1a" : "none"), width: "100%" }}>
            <Card sx={{ display: 'flex', width: "100%", boxShadow: "none", borderRadius: 0, backgroundColor: "#f0ecc9" }}>
              <CardMedia
                component="img"
                sx={{ width: 80 }}
                image={bank1}
                alt="Live from space album cover"
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto', padding: "0 8px !important" }}>
                  <Typography component="div" variant="h6" sx={{ color: "#058789", fontSize: "12px", fontWeight: "bold" }}>
                    {news.title}
                  </Typography>
                  <Typography variant="subtitle1" color="#503d2e" component="div" sx={{ fontSize: "10px" }}>
                    {news.subTitle}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Box>
        )}

        {totalItem < newsList.length &&
          <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
            <Link sx={{ fontSize: "12px", color: "#d54b1a" }} href="#/" underline="hover" onClick={loadMoreNews}>show more...</Link>
          </Box>
        }
      </div>
    </div>
  );
};

export default InvestorFlowNewsMobile;
