// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10rem;
  position: relative;

  .left-logo {
    background-color: #0b5cab;
    border-radius: 10rem 0 0 10rem;
    height: 20rem;
    padding-top: 3rem;
    width: 10rem;

    .left-logo-shadow {
      background-color: #032d60;
      border-radius: 8.5rem 0 0 8.5rem;
      height: 17rem;
      margin-left: 1.5rem;
      width: 8.5rem;
    }
  }

  .center-logo {
    background-color: #ffffff;
    height: 20rem;
    position: absolute;
    width: 2rem;

    .center-logo-inner {
      background-color: #ffffff;
      border-radius: 7rem;
      height: 14rem;
      margin-left: -6rem;
      margin-top: 3rem;
      position: absolute;
      width: 14rem;
    }
  }

  .right-logo {
    background-color: #fcc003;
    border-radius: 0 10rem 10rem 0;
    height: 20rem;
    padding-top: 3rem;
    width: 10rem;

    .right-logo-shadow {
      background-color: #f38303;
      border-radius: 0 8.5rem 8.5rem 0;
      height: 17rem;
      width: 8.5rem;
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/components/InvestorFlowLogo/InvestorFlowLogo.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,cAAc;EACd,kBAAkB;;EAElB;IACE,yBAAyB;IACzB,8BAA8B;IAC9B,aAAa;IACb,iBAAiB;IACjB,YAAY;;IAEZ;MACE,yBAAyB;MACzB,gCAAgC;MAChC,aAAa;MACb,mBAAmB;MACnB,aAAa;IACf;EACF;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,WAAW;;IAEX;MACE,yBAAyB;MACzB,mBAAmB;MACnB,aAAa;MACb,kBAAkB;MAClB,gBAAgB;MAChB,kBAAkB;MAClB,YAAY;IACd;EACF;;EAEA;IACE,yBAAyB;IACzB,8BAA8B;IAC9B,aAAa;IACb,iBAAiB;IACjB,YAAY;;IAEZ;MACE,yBAAyB;MACzB,gCAAgC;MAChC,aAAa;MACb,aAAa;IACf;EACF;AACF","sourcesContent":[".logo-wrapper {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  padding: 10rem;\n  position: relative;\n\n  .left-logo {\n    background-color: #0b5cab;\n    border-radius: 10rem 0 0 10rem;\n    height: 20rem;\n    padding-top: 3rem;\n    width: 10rem;\n\n    .left-logo-shadow {\n      background-color: #032d60;\n      border-radius: 8.5rem 0 0 8.5rem;\n      height: 17rem;\n      margin-left: 1.5rem;\n      width: 8.5rem;\n    }\n  }\n\n  .center-logo {\n    background-color: #ffffff;\n    height: 20rem;\n    position: absolute;\n    width: 2rem;\n\n    .center-logo-inner {\n      background-color: #ffffff;\n      border-radius: 7rem;\n      height: 14rem;\n      margin-left: -6rem;\n      margin-top: 3rem;\n      position: absolute;\n      width: 14rem;\n    }\n  }\n\n  .right-logo {\n    background-color: #fcc003;\n    border-radius: 0 10rem 10rem 0;\n    height: 20rem;\n    padding-top: 3rem;\n    width: 10rem;\n\n    .right-logo-shadow {\n      background-color: #f38303;\n      border-radius: 0 8.5rem 8.5rem 0;\n      height: 17rem;\n      width: 8.5rem;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
