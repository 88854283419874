import React from 'react';
import './InvestorFlowLogo.css';


const InvestorFlowLogo = () => {
  return (
    <div className="logo-wrapper">
      <div className="left-logo">
        <div className="left-logo-shadow" />
      </div>
      <div className="center-logo">
        <div className="center-logo-inner" />
      </div>
      <div className="right-logo">
        <div className="right-logo-shadow" />
      </div>
    </div>
  );
};

export default InvestorFlowLogo;
