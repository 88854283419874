import { useEffect, useState } from 'react';
import { Divider, List, Box, Link, Paper, Typography, ListItem } from '@mui/material';


const LinkPOC = () => {
  const [componentId, setComponentId] = useState();
  const [isReady, setIsReady] = useState(false);
  const [questionId, setQuestionId] = useState();

  useEffect(() => {
    if (window && !isReady) {
      window.addEventListener("message", messageHandler);
      if (window.parent) {
        window.parent.postMessage('READY', "*");
        console.log("fired ready event from React.");
        setIsReady(true);
      }
    }
  }, []);

  const messageHandler = (event) => {
    if (event.data?.hello || event.data?.type === 'webpackWarnings' || event.data === 'READY') return;
    console.log(event.data);
    try {
      const parsedData = JSON.parse(event.data);
      console.log(parsedData);
      setQuestionId(parsedData.params.questionId);
      setComponentId(parsedData.params.componentId);
    } catch (e) {
      console.log('error', e);
    };
  };

  return (
    <Box className="sample-body-link" >
      <Paper sx={{ width: "100%", border: "solid 1px rgba(0, 0, 0, 0.25)", padding: "1rem" }}>
        <Typography variant='h5' ><b>Hyperlinks</b></Typography>
        <Divider component="div" sx={{ paddingTop: "0.5rem" }} />
        <List sx={{ padding: "1rem" }}>
          <ListItem>
            <Link href={"https://stackoverflow.com/questions/" + questionId}
              target="_blank" underline="hover">Link To StackOverflow</Link>
          </ListItem>
          <ListItem>
            <Link href={"https://www.lightningdesignsystem.com/components/" + componentId}
              target="_blank" underline="hover">Link To SLDS</Link>
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
};

export default LinkPOC;
